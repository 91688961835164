
import Vue from "vue";
import VueRouter from "vue-router";
// import { getStore } from "@/util/store";
import store from "@/store";
import { homeLike } from "@/api/request/home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "HomeIndex",
        meta: {
          title: "主页",
          brotherLink: 'M_homeindex',
          type: 'web'
        },
        component: () => import("@/views/HomeIndex/index.vue"),
      },
      {
        path: "/M_homeindex",
        name: "M_homeindex",
        meta: {
          title: "主页",
          brotherLink: 'HomeIndex',
          type: 'phone'
        },
        component: () => import("@/views/M_homeindex/index.vue"),
      },
      {
        path: "/list",
        name: "listIndex",
        meta: {
          title: "列表详情",
          brotherLink: 'M_list',
          type: 'web'
        },
        component: () => import("@/views/list/index.vue"),
      },
      {
        path: "/M_list",
        name: "M_list",
        meta: {
          title: "列表详情",
          brotherLink: 'listIndex',
          type: 'phone'
        },
        component: () => import("@/views/M_list/index.vue"),
      },
      {
        path: "/search",
        name: "search",
        meta: {
          title: "搜索",
          brotherLink: 'M_search',
          type: 'web'
        },
        component: () => import("@/views/search/index.vue"),
      },
      {
        path: "/M_search",
        name: "M_search",
        meta: {
          title: "搜索",
          brotherLink: 'search',
          type: 'phone'
        },
        component: () => import("@/views/M_search/index.vue"),
      },
      {
        path: "/details",
        name: "details",
        meta: {
          title: "详情",
          brotherLink: 'M_details',
          type: 'web'
        },
        component: () => import("@/views/details/index.vue"),
      },
      {
        path: "/M_details",
        name: "M_details",
        meta: {
          title: "详情",
          brotherLink: 'details',
          type: 'phone'
        },
        component: () => import("@/views/M_details/index.vue"),
      },
      {
        path: "/PrincipalsBox",
        name: "PrincipalsBox",
        meta: {
          title: "校长信箱",
          brotherLink: 'M_PrincipalsBox',
          type: 'web'
        },
        component: () => import("@/views/PrincipalsBox/index.vue"),
      },
      {
        path: "/M_PrincipalsBox",
        name: "M_PrincipalsBox",
        meta: {
          title: "校长信箱",
          brotherLink: 'PrincipalsBox',
          type: 'phone'
        },
        component: () => import("@/views/M_PrincipalsBox/index.vue"),
      },
    ]
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   meta: {
  //     title: "登录"
  //   },
  //   component: () => import("@/views/login/index.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {

  if (!to.name) {
    next({
      name: 'HomeIndex'
    })
    return
  }
  let type = 'web'
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    // this.webType = "phone";

    store.commit("SET_WEB_TYPE", "phone");
    type = 'phone'
  }
  if (to.meta.type != type) {
    next({
      name: to.meta.brotherLink,
      query: to.query
    })
    return
  }
  if (to.meta.title) {
    document.title = `光华东山学校 | ${to.meta.title}`;
  } else {
    document.title = "光华东山学校";
  }
  next();
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router;
